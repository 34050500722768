import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IdentificationSearchResult} from "../../../record/identification-models";
import {IdWithIndex} from "../heraeus.module";

@Component({
  selector: 'heraeus-identification-list',
  templateUrl: './heraeus-identifications-list-view.component.html',
  styleUrls: ['./heraeus-identifications-list-view.component.scss']
})
export class HeraeusIdentificationsListViewComponent {

  @Input() identificationResults: IdentificationSearchResult[] = [];
  @Input() currentIdentificationId: string | null = null;
  @Input() dataset: string | null = null;

  @Output() setIdentificationEvent: EventEmitter<IdWithIndex> = new EventEmitter<IdWithIndex>();

}

