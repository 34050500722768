import {Component, Input} from '@angular/core';
import {StockItem} from "../../../record/feature-print-models";

@Component({
  selector: 'richemont-stock-item',
  templateUrl: './richemont-stock-item.component.html',
  styleUrls: ['./richemont-stock-item.component.scss']
})
export class RichemontStockItemComponent {

  @Input() stockItem: StockItem | null = null;

  getSerialNumber(): string {
    try {
      return this.stockItem?.externalIdentifier.split(" ")[1] || this.stockItem?.externalIdentifier || "";
    } catch (e: any) {
      return this.stockItem?.externalIdentifier || "";
    }
  }

  getCommercialRef(): string {
    try {
      return this.stockItem?.externalIdentifier.split(" ")[0] || this.stockItem?.externalIdentifier || "";
    } catch (e: any) {
      return this.stockItem?.externalIdentifier || "";
    }
  }
}
